html,
body {
    height: 100%;
}

html {
    overflow: hidden;
}

body {
    padding: 0;
    margin: 0;

    font-weight: 400;
    font-family: var(--font-primery);
    font-size: 16px;
    line-height: 22px;
    color: var(--color-black);
    background-color: var(--color-white);
    // perspective: 1px;
    // transform-style: preserve-3d;
    overflow-x: hidden;
}

a:hover {
    text-decoration: none;
}

.visually-hidden:not(:focus):not(:active),
input[type='checkbox'].visually-hidden,
input[type='radio'].visually-hidden {
    position: absolute;

    width: 1px;
    height: 1px;
    margin: -1px;
    border: 0;
    padding: 0;

    white-space: nowrap;

    clip-path: inset(100%);
    clip: rect(0 0 0 0);
    overflow: hidden;
}
