:root {
    --bg-body-color: var(--color-white);
    --bg-primery-color: var(--color-black);

    // ---------------
    --text-primery-color: var(--color-black);
    --icon-primery-color: var(--color-gray);
    --button-primery-color: var(--color-brown-first);
    --error-primery-color: var(--color-red);
}
