:root {
    --font-primery: 'Jost', 'Arial', sans-serif;
    --color-white: #ffffff;
    --color-black: #232323;
    --color-black-dark: #000000;
    --color-blue: #2e75b6;
    --color-blue-second: #deebf7;
    --color-gray: #9da1ae;
    --color-gray-light: #f1f2f4;
    --color-gray-dark: #737373;
    --color-brown-first: #c1b3b3;
    --color-brown-second: #7c5353;
    --color-brown-rgba70: rgba(124, 83, 83, 0.7);
    --color-green: #12a550;
    --color-red: #ea4b4b;
}
