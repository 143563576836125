@font-face {
  font-family: "Jost";
  font-weight: 800;
  font-style: normal;
  src: url(../fonts/jost-v13-latin_cyrillic-800.woff2) format("woff2"), url(../fonts/jost-v13-latin_cyrillic-800.woff) format("woff");
  font-display: swap;
}
@font-face {
  font-family: "Jost";
  font-weight: 400;
  font-style: normal;
  src: url(../fonts/jost-v13-latin_cyrillic-regular.woff2) format("woff2"), url(../fonts/jost-v13-latin_cyrillic-regular.woff) format("woff");
  font-display: swap;
}
/**
Andy Bell
https://hankchizljaw.com/wrote/a-modern-css-reset/
 */
/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default padding */
ul,
ol {
  padding: 0;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
ul,
ol,
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

/* Remove list styles on ul, ol elements with a class attribute */
ul,
ol {
  list-style: none;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img {
  max-width: 100%;
  display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Remove all animations and transitions for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
  -moz-appearance: textfield; /* Firefox */
}

:root {
  --font-primery: "Jost", "Arial", sans-serif;
  --color-white: #ffffff;
  --color-black: #232323;
  --color-black-dark: #000000;
  --color-blue: #2e75b6;
  --color-blue-second: #deebf7;
  --color-gray: #9da1ae;
  --color-gray-light: #f1f2f4;
  --color-gray-dark: #737373;
  --color-brown-first: #c1b3b3;
  --color-brown-second: #7c5353;
  --color-brown-rgba70: rgba(124, 83, 83, 0.7);
  --color-green: #12a550;
  --color-red: #ea4b4b;
}

:root {
  --bg-body-color: var(--color-white);
  --bg-primery-color: var(--color-black);
  --text-primery-color: var(--color-black);
  --icon-primery-color: var(--color-gray);
  --button-primery-color: var(--color-brown-first);
  --error-primery-color: var(--color-red);
}

html,
body {
  height: 100%;
}

html {
  overflow: hidden;
}

body {
  padding: 0;
  margin: 0;
  font-weight: 400;
  font-family: var(--font-primery);
  font-size: 16px;
  line-height: 22px;
  color: var(--color-black);
  background-color: var(--color-white);
  overflow-x: hidden;
}

a:hover {
  text-decoration: none;
}

.visually-hidden:not(:focus):not(:active),
input[type=checkbox].visually-hidden,
input[type=radio].visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}