@font-face {
    font-family: 'Jost';
    font-weight: 800;
    font-style: normal;
    src: url(../fonts/jost-v13-latin_cyrillic-800.woff2) format('woff2'), url(../fonts/jost-v13-latin_cyrillic-800.woff) format('woff');
    font-display: swap;
}

@font-face {
    font-family: 'Jost';
    font-weight: 400;
    font-style: normal;
    src: url(../fonts/jost-v13-latin_cyrillic-regular.woff2) format('woff2'),
        url(../fonts/jost-v13-latin_cyrillic-regular.woff) format('woff');
    font-display: swap;
}
